<script setup>
defineProps({
  className: {
    type: String,
    default: undefined,
  },
});

const {
  toggleIsScrollLocked,

  isSmBreakpoint,

  mobileMenuVisible,
  toggleMobileMenuVisible,

  globalStore,
} = await useLayout();
</script>

<template>
  <div class="layouts-default" :class="className">
    <ClientOnly>
      <Teleport to="body">
        <LayoutHeader
          v-model:mobile-menu-visible="mobileMenuVisible"
          data-layout-header
          class="layouts-default__header"
          :class="{
            'layouts-default__header--hide': !globalStore.headerVisible,
          }"
        />
      </Teleport>

      <template #fallback>
        <LayoutHeader
          class="layouts-default__header layouts-default__header--initial"
        />
      </template>
    </ClientOnly>

    <slot class-name="layouts-default__view" />

    <LazyLayoutFooter :settings="globalStore.settings" />

    <ClientOnly>
      <Teleport to="body">
        <Transition
          name="fade"
          @before-enter="toggleIsScrollLocked(true)"
          @after-leave="toggleIsScrollLocked(false)"
        >
          <LazyLayoutMobileMenu
            v-if="isSmBreakpoint && mobileMenuVisible"
            class="layouts-default__mobile-menu hidden-desktop"
            @link-click="toggleMobileMenuVisible()"
          />
        </Transition>
      </Teleport>

      <template #fallback />
    </ClientOnly>
  </div>
</template>

<style scoped lang="scss">
.layouts-default {
  $parent: &;

  display: flex;
  flex-direction: column;
  padding-top: em($header-height-with-bottom-gap);

  @include media-breakpoint-down(sm) {
    padding-top: em($header-height-with-top-gap-sm);
  }

  &__header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: em($header-height);
    z-index: 900;
    transition: transform $time-slow;

    @include media-breakpoint-down(sm) {
      top: em($header-top-gap-sm);
      left: em(10);
      right: em(10);
      height: em($header-height-sm);
    }

    &--hide {
      @include media-breakpoint-up(sm) {
        transform: translateY(-100%);
        opacity: 0;
        pointer-events: none;
        transition:
          transform $time-slow,
          opacity $time-fast $time-slow;
      }
    }

    &--initial {
      position: absolute;
    }
  }

  &:deep(#{$parent}__view) {
    flex-grow: 1;
  }

  &__mobile-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 800;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

:global(html) {
  scroll-padding-top: em($header-height-with-bottom-gap);

  @include media-breakpoint-down(sm) {
    scroll-padding-top: em($header-height-with-gaps-sm);
  }
}
</style>
