<script setup>
import { LOCALE_CODES, LOCALES } from '@/constants/variables';
import { enRouteNames } from '@/configs/menu';
import { useModal } from 'vue-final-modal';

const mobileMenuVisible = defineModel('mobileMenuVisible', {
  type: Boolean,
  default: false,
});
const toggleMobileMenuVisible = useToggle(mobileMenuVisible);

const router = useRouter();
const route = useRoute();

const breakpoint = useCustomBreakpoints();
const isSmBreakpoint = breakpoint.smaller('sm');

// search
const searchString = ref('');
const [isShowSearch, toggleIsShowSearch] = useToggle(false);
const isSearchPage = computed(() => route.name === 'search');
onMounted(() => {
  watch(
    () => route.query.q,
    (q) => {
      searchString.value = q || '';
    },
    { immediate: true },
  );
  watch(
    () => route.name,
    () => {
      toggleIsShowSearch(isSearchPage.value);
    },
    { immediate: true },
  );
});

function handleSearchButtonClick() {
  if (isSmBreakpoint.value) {
    searchModal.open();
  } else {
    isShowSearch.value = !isShowSearch.value;
  }
}
function goToSearch() {
  router.push({ name: 'search', query: { q: searchString.value } });
  toggleMobileMenuVisible(false);
}

const searchModal = useModal({
  component: resolveComponent('LazyModalsSearch'),
  attrs: {
    searchString,
    'onUpdate:searchString'(value) {
      searchString.value = value;
    },
    goToSearch,
  },
});

// language
const { locale } = useI18n();
const {
  public: { clientUrls },
} = useRuntimeConfig();
const oppositeLocale = computed(() =>
  locale.value === LOCALE_CODES.ru ? LOCALE_CODES.en : LOCALE_CODES.ru,
);
const isMounted = useMounted();
const languageButton = computed(() => {
  let path = '';

  if (enRouteNames.includes(route.name)) {
    path = isMounted.value ? route.fullPath : route.path;
  }

  return {
    name: LOCALES[oppositeLocale.value].name,
    url: `${clientUrls[oppositeLocale.value]}${path}`,
  };
});

// submenu
const hoveredMenuItem = ref(null);
function setHoveredMenuItem(value = null) {
  hoveredMenuItem.value = value;
}
let menuItemMouseLeaveTimeout = null;
function handleMenuItemMouseenter(item) {
  if (!item.submenu) return;

  clearMenuItemMouseLeaveTimeout();
  setHoveredMenuItem(item.name);
}
function handleMenuMouseleave() {
  menuItemMouseLeaveTimeout = setTimeout(setHoveredMenuItem, 150);
}
function clearMenuItemMouseLeaveTimeout() {
  clearTimeout(menuItemMouseLeaveTimeout);
}

// submenu offset left
const menuRef = ref(null);

const submenuOffsetLeft = ref(0);
const submenuOffsetLeftCss = computed(() => `${submenuOffsetLeft.value}px`);

function handleSubmenuTransitionEnter() {
  const menuLinkFontRef = menuRef.value.fontsRef.find(
    (fontRef) => fontRef.dataset.menuName === hoveredMenuItem.value,
  );

  submenuOffsetLeft.value = menuLinkFontRef.getBoundingClientRect().left;
}

const logo = useLogo();
</script>

<template>
  <header ref="root" class="layout-header container">
    <NuxtLink
      class="layout-header__link layout-header__link--logo"
      to="/"
      @click="toggleMobileMenuVisible(false)"
    >
      <img class="layout-header__logo" :src="logo" alt="Logo" />
    </NuxtLink>

    <div class="hidden-mobile">
      <LayoutMenu
        ref="menuRef"
        class="layout-header__menu"
        :hovered-menu-item="hoveredMenuItem"
        @item-mouseenter="handleMenuItemMouseenter"
        @item-mouseleave="handleMenuMouseleave"
      />

      <Transition name="fade">
        <!--LazyUiSubmenu-->
        <LayoutSubmenu
          v-if="!isSmBreakpoint && hoveredMenuItem"
          :current-menu-item-name="hoveredMenuItem"
          class="layout-header__submenu"
          @mouseenter="clearMenuItemMouseLeaveTimeout"
          @mouseleave="handleMenuMouseleave"
          @link-click="setHoveredMenuItem"
          @transition-enter="handleSubmenuTransitionEnter"
        />
      </Transition>
    </div>

    <div class="layout-header__nav">
      <ButtonsToggle
        class="layout-header__button layout-header__button--lang"
        :text="languageButton.name"
        :url="languageButton.url"
      />

      <!--      <ButtonsSearch-->
      <!--        class="layout-header__button layout-header__button&#45;&#45;search"-->
      <!--        @click="handleSearchButtonClick"-->
      <!--      />-->

      <ButtonsBurger
        class="link hidden-desktop"
        :opened="mobileMenuVisible"
        @click="toggleMobileMenuVisible()"
      />
    </div>

    <!--    <div class="layout-header__search-wrapper hidden-mobile">-->
    <!--      <Transition name="slide">-->
    <!--        <LazyUiSearch-->
    <!--          v-if="!isSmBreakpoint && isShowSearch"-->
    <!--          v-model:search-string="searchString"-->
    <!--          class="layout-header__search container"-->
    <!--          :show-search="!isSearchPage"-->
    <!--          auto-focus-->
    <!--          @close="toggleIsShowSearch"-->
    <!--          @submit="goToSearch"-->
    <!--        />-->
    <!--      </Transition>-->
    <!--    </div>-->
  </header>
</template>

<style scoped lang="scss">
.layout-header {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: em(31);
  padding-bottom: em(10);
  background-color: $color-white;

  @include media-breakpoint-down(sm) {
    padding: em(6) em(12);
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__submenu {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    z-index: 2;

    // stylelint-disable-next-line
    padding: em(10) em($gap) em(44) v-bind(submenuOffsetLeftCss);
  }

  &__logo {
    width: em($logo-width);
    height: em($logo-height);
    object-fit: contain;
    object-position: left center;

    @include media-breakpoint-down(sm) {
      width: em($logo-width-sm);
      height: em($logo-height-sm);
    }
  }

  &__search-wrapper {
    width: calc(100% - #{em($logo-width + $gap)});
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;
    pointer-events: none;
  }

  &__search {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    pointer-events: auto;
  }

  &__button {
    flex-shrink: 0;

    &--search {
      width: em(25);
      height: em(25);

      @include media-breakpoint-up(sm) {
        margin-left: em(29);
      }

      @include media-breakpoint-down(sm) {
        width: em(19);
        height: em(19);
        margin-right: em(27);
      }
    }

    &--lang {
      @include media-breakpoint-down(sm) {
        margin-right: em(35);
      }
    }
  }
}

.slide {
  &-enter-from,
  &-leave-to {
    transform: translateX(100%);
  }

  &-enter-active,
  &-leave-active {
    transition: transform $time-normal;
  }
}
</style>
