<script setup>
import { menu } from '@/configs/menu.js';

defineProps({
  hoveredMenuItem: {
    type: String,
    default: undefined,
  },
});
const emit = defineEmits(['linkClick', 'itemMouseenter', 'itemMouseleave']);

const nuxtLink = resolveComponent('nuxt-link');

const fontsRef = ref([]);

const { locale } = useI18n();
const localeMenu = computed(() => menu[locale.value]);

defineExpose({ fontsRef });
</script>

<template>
  <nav class="layout-menu">
    <ul class="layout-menu__list">
      <li
        v-for="(item, index) in localeMenu"
        :key="`layout-menu-item-${index}`"
        class="layout-menu__item"
      >
        <Component
          :is="item.submenu ? 'div' : nuxtLink"
          :to="!item.submenu ? item.url : undefined"
          class="layout-menu__link link"
          :class="{
            'link--active':
              (item.url && $route.path.startsWith(item.url)) ||
              hoveredMenuItem === item.name,
          }"
          @click="emit('linkClick')"
          @mouseenter="emit('itemMouseenter', item)"
          @mouseleave="emit('itemMouseleave')"
        >
          <span
            ref="fontsRef"
            :data-menu-name="item.name"
            class="layout-menu__font layout-menu__font--link"
          >
            {{ $tp($t(`layout.header.navLinks.${item.name}`)) }}
          </span>
        </Component>
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
.layout-menu {
  $parent: &;

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__link {
    display: block;
    padding: em(10) em(20);
  }

  &__font {
    display: block;

    &--link {
      font-size: em(24);
      line-height: em(19, 24);

      @include media-breakpoint-down(sm) {
        font-size: em(26);
        line-height: 1.8;
      }
    }
  }
}
</style>
