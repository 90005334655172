import { LOCALE_CODES } from '@/constants/variables';

const logos = {
  [LOCALE_CODES.ru]: '/images/logo.svg',
  [LOCALE_CODES.en]: '/images/logo-en.svg',
};

export const useLogo = () => {
  const { locale } = useI18n();

  return computed(() => logos[locale.value]);
};
