<script setup>
import { menu } from '@/configs/menu.js';

const props = defineProps({
  currentMenuItemName: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['linkClick', 'transitionEnter']);

const listHeight = ref(0);
const listRef = ref(null);

const { locale } = useI18n();
const localeMenu = computed(() => menu[locale.value]);

const submenu = computed(() => {
  return (
    localeMenu.value.find((item) => item.name === props.currentMenuItemName)
      ?.submenu || []
  );
});

function handleEnter() {
  listHeight.value = listRef.value.scrollHeight;

  emit('transitionEnter');
}
</script>

<template>
  <div class="layout-submenu">
    <Transition
      name="layout-submenu-fade"
      mode="out-in"
      appear
      @enter="handleEnter"
    >
      <div
        :key="currentMenuItemName"
        class="layout-submenu__wrapper"
        :style="{ height: `${listHeight}px` }"
      >
        <ul ref="listRef" class="layout-submenu__list">
          <li
            v-for="(item, index) in submenu"
            :key="`layout-submenu-item-${index}`"
            class="layout-submenu__item"
          >
            <NuxtLink
              :to="item.url"
              :target="item.target"
              class="layout-submenu__link link"
              active-class="link--active"
              @click="emit('linkClick')"
            >
              <span class="layout-submenu__font layout-submenu__font--link">
                {{ $tp($t(`layout.header.navLinks.${item.name}`)) }}
              </span>
            </NuxtLink>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.layout-submenu {
  $parent: &;
  width: 100%;
  background: $color-white;
  border-bottom: 1px solid $color-gray-1;

  &__wrapper {
    overflow: hidden;
    transition: height $time-normal;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__item {
    flex-shrink: 0;
  }

  &__font {
    display: block;

    &--link {
      font-size: em(22);
      line-height: em(40, 22);

      @include media-breakpoint-down(sm) {
        font-size: em(26);
        line-height: 1.8;
      }
    }
  }

  &__link {
    display: block;
  }
}

.layout-submenu-fade {
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }

  &-enter-active,
  &-leave-active {
    transition:
      opacity $time-normal,
      height $time-normal;
  }
}
</style>
